// 
// dropdown.scss
//

.dropdown-menu {
    box-shadow: $box-shadow;
    animation-name: DropDownSlide;
    animation-duration: .3s;
    animation-fill-mode: both;
    margin: 0;
    font-size: $font-size-base;
    position: absolute;
    z-index: 1000;

    &.show {
        top: 100%!important;
    }
}

.dropdown-divider{
    border-color: $border-color;
}

.dropdown-menu-right {
    right: 0!important;
    left: auto!important;
}

.dropdown-menu[x-placement^=right],.dropdown-menu[x-placement^=top],.dropdown-menu[x-placement^=left]{
    top: auto !important;
    animation: none !important;
}

@keyframes DropDownSlide {
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    0% {
        -webkit-transform: translateY(40px);
        transform: translateY(40px)
    }
}


// Dropdown Large (Custom)
.dropdown-lg {
    width: $dropdown-lg-width;
}

@include media-breakpoint-down(sm) {
    .dropdown-lg {
        width: 200px !important;
    }
}

.dropdown-example {
    position: static;
    display: block;
    margin-bottom: .25rem;
    clear: left;
    animation: none;
}

// Dropdown Mega Menu

.dropdown-mega {
    position: static!important;
}

.dropdown-megamenu {
    padding: 20px;
    left: 20px !important;
    right: 20px !important;
}

.megamenu-list {
    li {
        padding: 5px 0px;
        position: relative;

        a {
            color: $menu-item;

            &:hover {
                color: $primary;
            }
        }
    }
}