// 
// accordions.scss
//

.custom-accordion {
    .card {
        + .card {
            margin-top: 0.5rem;
        }
    }

    a {
        &.collapsed {
            i.accor-plus-icon {
                &:before {
                    content: "\F419";
                }
            }
        }
    }
}